/* Copyright G. Hemingway, 2024 - All rights reserved */
"use strict";

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import {
  ErrorMessage,
  FormBase,
  FormLabel,
  FormInput,
  FormButton,
} from "./shared.js";
import styled from "styled-components";

const StyledForm = styled(FormBase)`
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem 0;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  width: 100%;
`;

const StyledLabel = styled(FormLabel)`
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  min-width: 80px;
`;

const Divider = styled.div`
  width: 90%;
  text-align: center;
  border-bottom: 1px solid #ddd;
  line-height: 0;
  margin: 1rem 0;

  span {
    background: #f6f8fa;
    padding: 0 5px;
    color: #666;
    font-size: 14px;
  }
`;

const StyledInput = styled(FormInput)`
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  width: 100%;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.15);
  }

  &::placeholder {
    color: #aaa;
  }
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px); 
  background-color: transparent;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background: #f6f8fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
`;

const BaseButton = styled.button`
  width: 60%;
  display: flex;
  justify-content: center;
  background-color: dodgerblue;
  align-items: center;
  color: #fff;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  padding: 15px 0;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin: 0 auto;
`;

const StyledButton = styled(BaseButton)`
  background-color: dodgerblue;
  margin-bottom: -1rem;

  &:hover {
    background-color: #53aaff;
  }
`;

const GitHubButton = styled(BaseButton)`
  background-color: #333;
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  &:hover {
    background-color: #444;
  }

  img {
    margin-right: 10px;
  }
`;

const ButtonContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.5rem;
`;

export const Login = (props) => {
  let navigate = useNavigate();
  let [username, setUser] = useState("");
  let [password, setPass] = useState("");
  let [error, setError] = useState("");

  const onSubmit = async (ev) => {
    ev.preventDefault();
    let res = await fetch("/v1/session", {
      body: JSON.stringify({
        username,
        password,
      }),
      method: "POST",
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
    });
    const data = await res.json();
    if (res.ok) {
      props.logIn(data.username);
      navigate(`/profile/${data.username}`);
    } else {
      setError(`Error: ${data.error}`);
    }
  };

  useEffect(() => {
    document.getElementById("username").focus();
  }, []);

  return (
      <div style={{gridArea: 'main'}}>
        <CenteredContainer>
          <FormContainer>
            <ErrorMessage msg={error}/>
            <StyledForm>
              <InputGroup>
                <StyledLabel htmlFor="username">Username</StyledLabel>
                <StyledInput
                    id="username"
                    name="username"
                    type="text"
                    placeholder="Enter your username"
                    value={username}
                    onChange={(ev) => setUser(ev.target.value.toLowerCase())}
                />
              </InputGroup>
              <InputGroup>
                <StyledLabel htmlFor="password">Password</StyledLabel>
                <StyledInput
                    id="password"
                    name="password"
                    type="password"
                    placeholder="Enter your password"
                    value={password}
                    onChange={(ev) => setPass(ev.target.value)}
                />
              </InputGroup>
              <StyledButton id="submitBtn" onClick={onSubmit}>
                Login
              </StyledButton>
            </StyledForm>
            <Divider><span>OR</span></Divider>
            <ButtonContainer>
              <GitHubButton
                  onClick={(e) => {
                    e.preventDefault();
                    window.location.href = "/auth/github";
                  }}
              >
                <img
                    src="/images/github-mark-white.png"
                    alt="GitHub Logo"
                    width="20"
                    height="20"
                />
                Login with GitHub
              </GitHubButton>
            </ButtonContainer>
          </FormContainer>
        </CenteredContainer>
      </div>
  );
};

Login.propTypes = {
  logIn: PropTypes.func.isRequired,
};
