/* Copyright G. Hemingway, 2024 - All rights reserved */
"use strict";

import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { GravHash } from "./header.js";
import { GameList } from "./game-list.js";
import {
  ErrorMessage,
  InfoBlock,
  InfoData,
  InfoLabels,
  ShortP,
} from "./shared.js";

const ProfileBlockBase = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  grid-template-areas: "pic" "profile";
  padding: 1em;

  @media (min-width: 500px) {
    grid-template-columns: auto 1fr;
    grid-template-areas: "pic profile";
    padding: 2em;
  }
`;

const ProfileImage = styled.img`
  grid-area: pic;
  max-width: 150px;
  padding: 1em;
  @media (min-width: 500px) {
    padding: 0.5em;
    max-width: 200px;
  }
`;

const EditButton = styled(Link)`
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 6px 16px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 0.9rem;
  transition: background-color 0.2s;
  margin-top: 8px;

  &:hover {
    background-color: #0056b3;
    color: white;
    text-decoration: none;
  }
`;

const GitHubBadge = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: #24292e;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  gap: 4px;
`;


const ProfileBlock = (props) => {
  const isGitHubUser = props.github && props.github.id;

  return (
    <ProfileBlockBase>
      <ProfileImage
          src={isGitHubUser ? props.github.avatar_url : GravHash(props.primary_email, 200)}
      />
      <InfoBlock>
        <InfoLabels>
          <p>Username:</p>
          <p>First Name:</p>
          <p>Last Name:</p>
          <p>City:</p>
          <p>Email Address:</p>
          {isGitHubUser && <p>GitHub Profile:</p>}
          {isGitHubUser && props.github.bio && <p>Bio:</p>}
        </InfoLabels>
        <InfoData>
          <ShortP>{props.username}</ShortP>
          <ShortP>{props.first_name || 'Not provided'}</ShortP>
          <ShortP>{props.last_name || 'Not provided'}</ShortP>
          <ShortP>{props.city || 'Not provided'}</ShortP>
          <ShortP>{props.primary_email}</ShortP>
          {isGitHubUser && (
              <ShortP>
                <a
                    href={props.github.profile_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: '#007bff', textDecoration: 'none' }}
                >
                  View GitHub Profile
                </a>
              </ShortP>
          )}
          {isGitHubUser && props.github.bio && (
              <ShortP>{props.github.bio}</ShortP>
          )}
          <ShortP>
            {props.isCurrentUser && (
                <EditButton to={`/profile/${props.username}/edit`}>
                  Edit Profile
                </EditButton>
            )}
          </ShortP>
        </InfoData>
      </InfoBlock>
    </ProfileBlockBase>
  );
};

const EditLinkBase = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  display: block;

  & > a {
    cursor: pointer;
    background-color: #007bff;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    text-decoration: none;
    display: inline-block;

    &:hover {
      background-color: #0056b3;
    }
  }
`;

const EditLink = ({ show, username }) => {
  if (show) {
    console.log(`/profile/${username}/edit`);
  }
  return show ? (
      <EditLinkBase>
        <Link to={`/profile/${username}/edit`}>Edit Profile</Link>
      </EditLinkBase>
  ) : null;
};


const ProfileBase = styled.div`
  grid-area: main;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
`;


export const Profile = (props) => {
  const { username } = useParams();
  let [state, setState] = useState({
    username: "",
    first_name: "",
    last_name: "",
    primary_email: "",
    city: "",
    games: [],
    error: "",
  });

  useEffect(() => {
    fetchUser(username);
  }, [username]);

  const fetchUser = async (username) => {
    try {
      const res = await fetch(`/v1/user/${username}`);
      const data = await res.json();
      if (res.ok) {
        setState(data);
      } else {
        console.error('Error fetching user:', data.error);
      }
    } catch (err) {
      console.error('Error:', err);
    }
  };

  // Is the logged-in user viewing their own profile
  const isUser = state.username === props.currentUser ||
      (state.github && props.currentUser === state.username);
  const isGitHubUser = state.github && state.github.id;
  const canCreateGames = isUser || (state.github && state.github.id && state.username === props.currentUser);

  return (
      <Fragment>
        <ProfileBase>
          {isGitHubUser && (
              <GitHubBadge>
                <img
                    src="/images/github-mark-white.png"
                    alt="GitHub"
                    style={{ width: '16px', height: '16px' }}
                />
                GitHub User
              </GitHubBadge>
          )}
          <ErrorMessage msg={state.error} hide={true} />
          <ProfileBlock {...state} isCurrentUser={isUser} />
          <GameList toCreateGame={canCreateGames} games={state.games || []} />
        </ProfileBase>
      </Fragment>
  );
};

Profile.propTypes = {
  gridPlacement: PropTypes.string,
  user: PropTypes.string,
};
