/* Copyright G. Hemingway, 2024 - All rights reserved */
"use strict";

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Link } from "react-router-dom";
import md5 from "md5";

/**
 * @return {string}
 */
export function GravHash(email, size) {
  let hash = email && email.replace(/^\s\s*/, "").replace(/\s\s*$/, "");
  hash = hash && hash.toLowerCase();
  hash = hash && md5(hash);
  return `https://www.gravatar.com/avatar/${hash}?size=${size}`;
}

const fontColor = "#ffffff";

const HeaderBase = styled.div`
  grid-area: hd;
  display: flex;
  background: #1a1a1a;
  padding: 0 2rem;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 60px;
`;

const HeaderLeftBase = styled.div`
  flex-grow: 1;
  font-style: italic;
  
  & > h2, & > a > h2 {
    color: ${fontColor};
    font-size: 1.5rem;
    font-weight: 600;
    margin: 0;
    transition: color 0.2s ease;
  }

  & > a {
    text-decoration: none;
    
    &:hover > h2 {
      color: #007bff;
    }
  }
`;

const HeaderRightBase = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding-right: 0.5em;

  & > a {
    color: ${fontColor};
    text-decoration: none;
    padding: 0.5rem;
    border-radius: 4px;
    transition: all 0.2s ease;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }
  }

  & > a > img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 2px solid transparent;
    transition: border-color 0.2s ease;
    vertical-align: middle;

    &:hover {
      border-color: #007bff;
    }
  }
`;

const HeaderLeft = () => {
  return (
      <HeaderLeftBase>
          <Link to={`/`}>
              <h2>GrahamCard</h2>
          </Link>
      </HeaderLeftBase>
  );
};

const HeaderRight = ({ user, email }) => {
  const isLoggedIn = user !== "";
  return (
      <HeaderRightBase $vertical={isLoggedIn}>
        {isLoggedIn ? (
            <Fragment>
              <Link to="/logout">Log Out</Link>
              <Link to={`/profile/${user}`}>
                <img src={GravHash(email, 40)} alt={`${user}'s profile`} />
              </Link>
            </Fragment>
        ) : (
            <Fragment>
              <Link id="loginLink" to="/login">
                Log In
              </Link>
              <Link id="regLink" to="/register">
                Register
              </Link>
            </Fragment>
        )}
      </HeaderRightBase>
  );
};

/*******************************************************************/

export const Header = ({ user = "", email = "" }) => (
    <HeaderBase>
      <HeaderLeft user={user} />
      <HeaderRight user={user} email={email} />
    </HeaderBase>
);

HeaderLeft.propTypes = {
  user: PropTypes.string,
};

HeaderRight.propTypes = {
  user: PropTypes.string,
  email: PropTypes.string,
};

Header.propTypes = {
  user: PropTypes.string,
  email: PropTypes.string,
};