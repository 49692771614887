/* Copyright G. Hemingway, 2024 - All rights reserved */
"use strict";

import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { Pile } from "./pile.js";

const CardRow = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 2em;
`;

const CardRowGap = styled.div`
  flex-grow: 2;
`;

const GameBase = styled.div`
  grid-row: 2;
  grid-column: sb / main;
  min-height: 100vh;
  padding: 1em;
  background-color: #00a01b;
`;

export const Game = () => {
  const { id } = useParams();
  const [state, setState] = useState({
    pile1: [], pile2: [], pile3: [], pile4: [], pile5: [], pile6: [], pile7: [],
    stack1: [], stack2: [], stack3: [], stack4: [],
    draw: [], discard: []
  });
  const [sourcePile, setSourcePile] = useState("");
  const [cardsToMove, setCardsToMove] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [drawCount, setDrawCount] = useState(1);

  const resetSelection = () => {
    setSourcePile("");
    setCardsToMove([]);
    setErrorMessage("");
  };

  useEffect(() => {
    const getGameState = async () => {
      const response = await fetch(`/v1/game/${id}`);
      const data = await response.json();
      setDrawCount(data.drawCount);
      setState(data);
    };
    getGameState();
  }, [id]);

  const makeMove = async (tempState, requestedMove) => {
    try {
      const moveData = {
        cards: requestedMove.cardStack,
        src: requestedMove.source.name,
        dst: requestedMove.destination.name
      };

      console.log('Making move:', moveData);

      const response = await fetch(`/v1/game/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(moveData)  // Don't include tempState
      });

      if (response.ok) {
        const data = await response.json();
        setState(data);
        setErrorMessage("");
      } else {
        const error = await response.json();
        console.log('Move failed:', error);
        setErrorMessage(error.error || "Invalid move");
      }
    } catch (err) {
      console.error('Failed to process move:', err);
      setErrorMessage("Failed to process move");
    }
  };

  const resetDraw = async () => {
    if (state.draw.length !== 0) return;

    let tempState = JSON.parse(JSON.stringify(state));
    let cardStack = [];

    while (tempState.discard.length > 0) {
      let card = tempState.discard.pop();
      card.up = false;
      tempState.draw.push(card);
      cardStack.push(card);
    }

    await makeMove(tempState, {
      source: { name: "discard", cards: state.discard },
      destination: { name: "draw", cards: state.draw },
      cardStack: cardStack
    });
  };

  const onClick = async (ev, pileName) => {
    if (ev && ev.target.id) {
      console.log('Click event:', {
        id: ev.target.id,
        pileName: pileName,
        currentCards: state[pileName]
      });
    }

    if (ev && ev.target.src.includes('face_down.jpg') && pileName !== 'draw') {
      return;
    }

    let tempState = JSON.parse(JSON.stringify(state));
    let movedCards = [];

    if (pileName === 'draw') {
      if (tempState.draw.length === 0) {
        console.log('Draw pile is empty');
        return;
      }

      console.log('Drawing cards from draw pile');
      const numToDraw = Math.min(drawCount, tempState.draw.length);
      for (let i = 0; i < numToDraw; i++) {
        let card = tempState.draw.pop();
        if (i === numToDraw - 1) card.up = true;
        movedCards.push(card);
        tempState.discard.push(card);
      }

      await makeMove(tempState, {
        cardStack: movedCards,
        source: { name: 'draw', cards: state.draw },
        destination: { name: 'discard', cards: state.discard }
      });
      return;
    }

    if (!sourcePile) {
      if (!ev) return;

      console.log('First click:', ev.target.id);
      const [suit, value] = ev.target.id.split(':');
      const pileCards = tempState[pileName];
      const clickedCardIndex = pileCards.findIndex(card =>
          card.suit === suit && card.value === value && card.up
      );

      console.log('Found card:', pileCards[clickedCardIndex]);
      if (clickedCardIndex === -1) return;

      if (value === 'ace' && !pileName.startsWith('stack')) {
        let destinationStack;
        switch(suit) {
          case 'spades': destinationStack = 'stack1'; break;
          case 'hearts': destinationStack = 'stack2'; break;
          case 'clubs': destinationStack = 'stack3'; break;
          case 'diamonds': destinationStack = 'stack4'; break;
        }

        const cardToMove = [pileCards[clickedCardIndex]];
        tempState[pileName] = pileCards.slice(0, clickedCardIndex).concat(pileCards.slice(clickedCardIndex + 1));

        if (tempState[pileName].length > 0) {
          tempState[pileName][tempState[pileName].length - 1].up = true;
        }

        await makeMove(tempState, {
          cardStack: cardToMove,
          source: { name: pileName, cards: state[pileName] },
          destination: { name: destinationStack, cards: state[destinationStack] }
        });
        return;
      }

      if (value === 'king') {
        console.log('King card clicked');
        console.log('Pile name:', pileName);
        console.log('Current state:', tempState[pileName]);
        console.log('Card value:', value);
        console.log('Card suit:', suit);
      }

      if (value === 'king' && !pileName.startsWith('stack')) {
        console.log('King card clicked:', value, 'from', pileName);

        const kingCards = pileCards.slice(clickedCardIndex);

        if (kingCards.length > 0) {
          for (let i = 1; i <= 7; i++) {
            const targetPile = `pile${i}`;
            if (targetPile !== pileName && tempState[targetPile].length === 0) {
              console.log('Found empty pile for king:', targetPile);

              tempState[pileName] = pileCards.slice(0, clickedCardIndex);
              if (tempState[pileName].length > 0) {
                tempState[pileName][tempState[pileName].length - 1].up = true;
              }

              await makeMove(tempState, {
                cardStack: kingCards,
                source: { name: pileName, cards: state[pileName] },
                destination: { name: targetPile, cards: state[targetPile] }
              });

              resetSelection();
              return;
            }
          }
        }
      }

      movedCards = pileCards.slice(clickedCardIndex);
      setSourcePile(pileName);
      setCardsToMove(movedCards);
      console.log("Selected cards:", movedCards);
      return;
    }

    if (pileName === "discard") {
      resetSelection();
      return;
    }

    if (pileName === sourcePile) {
      console.log('Cannot move cards to the same pile');
      resetSelection();
      return;
    }

    if (!cardsToMove || cardsToMove.length === 0) {
      console.log("No cards selected to move");
      resetSelection();
      return;
    }

    tempState[sourcePile] = tempState[sourcePile].slice(0, -cardsToMove.length);

    tempState[pileName] = [...tempState[pileName], ...cardsToMove];

    if (tempState[sourcePile].length > 0) {
      tempState[sourcePile][tempState[sourcePile].length - 1].up = true;
    }

    await makeMove(tempState, {
      cardStack: cardsToMove,
      source: { name: sourcePile, cards: state[sourcePile] },
      destination: { name: pileName, cards: state[pileName] }
    });

    resetSelection();
  };

  const ErrorBanner = styled.div`
    color: red;
    padding: 1em;
    text-align: center;
    font-weight: bold;
  `;

  return (
      <GameBase onClick={(ev) => {
        if (ev.target.tagName !== "IMG") {
          resetSelection();
        }
      }}>
        {errorMessage && <ErrorBanner>{errorMessage}</ErrorBanner>}
        <CardRow>
          <Pile cards={state.stack1} spacing={0} onClick={(ev) => onClick(ev, "stack1")}
                selectedCards={sourcePile === "stack1" ? cardsToMove : []} />
          <Pile cards={state.stack2} spacing={0} onClick={(ev) => onClick(ev, "stack2")}
                selectedCards={sourcePile === "stack2" ? cardsToMove : []} />
          <Pile cards={state.stack3} spacing={0} onClick={(ev) => onClick(ev, "stack3")}
                selectedCards={sourcePile === "stack3" ? cardsToMove : []} />
          <Pile cards={state.stack4} spacing={0} onClick={(ev) => onClick(ev, "stack4")}
                selectedCards={sourcePile === "stack4" ? cardsToMove : []} />
          <CardRowGap />
          <Pile cards={state.draw} spacing={0} onClick={(ev) => onClick(ev, "draw")} />
          <Pile cards={state.discard} spacing={0} onClick={(ev) => onClick(ev, "discard")}
                selectedCards={sourcePile === "discard" ? cardsToMove : []} />
        </CardRow>
        <div style={{ textAlign: 'right', marginBottom: '1em', marginRight: '3em' }}>
          <button
              onClick={resetDraw}
              disabled={state.draw.length > 0}
              style={{
                padding: '0.5em 1em',
                backgroundColor: state.draw.length === 0 ? '#ff0000' : '#9c9c9c',
                color: state.draw.length === 0 ? 'white' : '#8c8c8c',
                boxShadow: state.draw.length === 0 ? '0 4px 8px rgba(0, 0, 0, 0.2)' : 'none',
                transition: 'box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out',
                border: 'none',
                borderRadius: '4px',
                cursor: state.draw.length === 0 ? 'pointer' : 'not-allowed'
              }}
          >
            Reset Draw Pile
          </button>
        </div>
        <CardRow>
          <Pile cards={state.pile1} onClick={(ev) => onClick(ev, "pile1")} selectedCards={sourcePile === "pile1" ? cardsToMove : []}/>
          <Pile cards={state.pile2} onClick={(ev) => onClick(ev, "pile2")} selectedCards={sourcePile === "pile2" ? cardsToMove : []}/>
          <Pile cards={state.pile3} onClick={(ev) => onClick(ev, "pile3")} selectedCards={sourcePile === "pile3" ? cardsToMove : []}/>
          <Pile cards={state.pile4} onClick={(ev) => onClick(ev, "pile4")} selectedCards={sourcePile === "pile4" ? cardsToMove : []}/>
          <Pile cards={state.pile5} onClick={(ev) => onClick(ev, "pile5")} selectedCards={sourcePile === "pile5" ? cardsToMove : []}/>
          <Pile cards={state.pile6} onClick={(ev) => onClick(ev, "pile6")} selectedCards={sourcePile === "pile6" ? cardsToMove : []}/>
          <Pile cards={state.pile7} onClick={(ev) => onClick(ev, "pile7")} selectedCards={sourcePile === "pile7" ? cardsToMove : []}/>
        </CardRow>
      </GameBase>
  );
};

Game.propTypes = {};
