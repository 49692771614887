/* Copyright G. Hemingway, 2024 - All rights reserved */
"use strict";

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ErrorMessage,
  FormBase,
  FormInput,
  FormLabel,
  FormButton,
  ModalNotify,
} from "./shared.js";
import { validPassword, validUsername } from "../../shared/index.js";
import styled from "styled-components";

const StyledForm = styled(FormBase)`
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  padding: 0.5rem 0;
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  width: 100%;
`;

const StyledLabel = styled(FormLabel)`
  font-size: 1rem;
  font-weight: 500;
  color: #333;
  min-width: 120px;
`;

const StyledInput = styled(FormInput)`
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  width: 100%;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.15);
  }

  &::placeholder {
    color: #aaa;
  }
`;

const Divider = styled.div`
  width: 90%;
  text-align: center;
  border-bottom: 1px solid #ddd;
  line-height: 0;
  margin: 1rem 0;

  span {
    background: #f6f8fa;
    padding: 0 5px;
    color: #666;
    font-size: 14px;
  }
`;

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);
  background-color: transparent;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background: #f6f8fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 500px;
`;

const BaseButton = styled.button`
  width: 60%;
  display: flex;
  justify-content: center;
  background-color: dodgerblue;
  align-items: center;
  color: #fff;
  font-size: 20px;
  border: none;
  border-radius: 5px;
  padding: 15px 0;
  cursor: pointer;
  transition: background-color 0.2s ease;
  margin: 0 auto;
`;

const StyledButton = styled(BaseButton)`
  background-color: dodgerblue;
  margin-bottom: -1rem;

  &:hover {
    background-color: #53aaff;
  }
`;

const GitHubButton = styled(BaseButton)`
  background-color: #333;
  margin-top: 0.5rem;
  margin-bottom: 1rem;

  &:hover {
    background-color: #444;
  }

  img {
    margin-right: 10px;
  }
`;

const ButtonContainer = styled.div`
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 0.5rem;
`;

export const Register = () => {
  let navigate = useNavigate();
  let [state, setState] = useState({
    username: "",
    first_name: "",
    last_name: "",
    city: "",
    primary_email: "",
    password: "",
  });
  let [error, setError] = useState("");
  let [notify, setNotify] = useState("");

  useEffect(() => {
    document.getElementById("username").focus();
  }, []);

  const onChange = (ev) => {
    setError("");
    // Update from form and clear errors
    setState({
      ...state,
      [ev.target.name]: ev.target.value,
    });
    // Make sure the username is valid
    if (ev.target.name === "username") {
      let usernameInvalid = validUsername(ev.target.value);
      if (usernameInvalid) setError(`Error: ${usernameInvalid.error}`);
    }
    // Make sure password is valid
    else if (ev.target.name === "password") {
      let pwdInvalid = validPassword(ev.target.value);
      if (pwdInvalid) setError(`Error: ${pwdInvalid.error}`);
    }
  };

  const onSubmit = async (ev) => {
    ev.preventDefault();
    // Only proceed if there are no errors
    if (error !== "") return;
    const res = await fetch("/v1/user", {
      method: "POST",
      body: JSON.stringify(state),
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
    });
    if (res.ok) {
      // Notify users
      setNotify(`${state.username} registered.  You will now need to log in.`);
    } else {
      const err = await res.json();
      setError(err.error);
    }
  };

  const onAcceptRegister = () => {
    navigate("/login");
  };

  return (
    <div style={{ gridArea: "main" }}>
      {notify !== "" ? (
        <ModalNotify
          id="notification"
          msg={notify}
          onAccept={onAcceptRegister}
        />
      ) : null}
      <CenteredContainer>
        <FormContainer>
          <ErrorMessage msg={error} />
          <StyledForm>
            <InputGroup>
              <StyledLabel htmlFor="username">Username</StyledLabel>
              <StyledInput
                  id="username"
                  name="username"
                  placeholder="Enter your username"
                  onChange={onChange}
                  value={state.username}
              />
            </InputGroup>
            <InputGroup>
              <StyledLabel htmlFor="first_name">First Name</StyledLabel>
              <StyledInput
                  id="first_name"
                  name="first_name"
                  placeholder="Enter your first name"
                  onChange={onChange}
                  value={state.first_name}
              />
            </InputGroup>
            <InputGroup>
              <StyledLabel htmlFor="last_name">Last Name</StyledLabel>
              <StyledInput
                  id="last_name"
                  name="last_name"
                  placeholder="Enter your last name"
                  onChange={onChange}
                  value={state.last_name}
              />
            </InputGroup>
            <InputGroup>
              <StyledLabel htmlFor="city">City</StyledLabel>
              <StyledInput
                  id="city"
                  name="city"
                  placeholder="Enter your city"
                  onChange={onChange}
                  value={state.city}
              />
            </InputGroup>
            <InputGroup>
              <StyledLabel htmlFor="primary_email">Email</StyledLabel>
              <StyledInput
                  id="primary_email"
                  name="primary_email"
                  type="email"
                  placeholder="Enter your email address"
                  onChange={onChange}
                  value={state.primary_email}
              />
            </InputGroup>
            <InputGroup>
              <StyledLabel htmlFor="password">Password</StyledLabel>
              <StyledInput
                  id="password"
                  name="password"
                  type="password"
                  placeholder="Enter your password"
                  onChange={onChange}
                  value={state.password}
              />
            </InputGroup>
            <StyledButton id="submitBtn" onClick={onSubmit}>
              Register
            </StyledButton>
          </StyledForm>
          <Divider>
            <span>OR</span>
          </Divider>
          <ButtonContainer>
            <GitHubButton
                onClick={(e) => {
                  e.preventDefault();
                  window.location.href = "/auth/github";
                }}
            >
              <img
                  src="/images/github-mark-white.png"
                  alt="GitHub Logo"
                  width="20"
                  height="20"
              />
              Register with GitHub
            </GitHubButton>
          </ButtonContainer>
        </FormContainer>
      </CenteredContainer>
    </div>
  );
};

Register.propTypes = {};
