/* Copyright G. Hemingway, 2024 - All rights reserved */
"use strict";

import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";

const jiggle = keyframes`
  0%, 100% {
    transform: translate(0px, 0px) rotate(0deg);
  }
  25% {
    transform: translate(-3px, 3px) rotate(-5deg);
  }
  50% {
    transform: translate(3px, -3px) rotate(5deg);
  }
  75% {
    transform: translate(-2px, 2px) rotate(-3deg);
  }
`;

const floatAnimation = keyframes`
  0% {
    transform: translateY(0) translateX(0) rotate(0deg);
  }
  25% {
    transform: translateY(-200px) translateX(100px) rotate(20deg);
  }
  50% {
    transform: translateY(200px) translateX(-100px) rotate(-20deg);
  }
  75% {
    transform: translateY(-150px) translateX(50px) rotate(15deg);
  }
  100% {
    transform: translateY(0) translateX(0) rotate(0deg);
  }
`;

const LandingBase = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100vw;
  background-color: #00a01b;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  z-index: -1;
`;

const JigglingText = styled.h1`
  position: absolute;
  font-family: 'Arial', sans-serif;
  font-size: clamp(32px, 6vw, 72px);
  font-weight: bold;
  color: white;
  text-shadow:
    0 0 5px rgba(0, 255, 0, 1),
    0 0 10px rgba(0, 255, 0, 0.7),
    0 0 20px rgba(0, 255, 0, 0.5),
    0 0 30px rgba(0, 255, 0, 0.3),
    0 0 40px rgba(0, 255, 0, 0.2);
  animation: ${jiggle} 1s infinite;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  margin: 0;
  padding: 0;
`;

const Card = styled.img`
  position: absolute;
  width: 100px;
  height: 150px;
  animation: ${floatAnimation} ${({ duration }) => duration}s infinite ease-in-out;
  top: ${({ top }) => top}%;
  left: ${({ left }) => left}%;
  transform: rotate(${({ rotation }) => rotation}deg);
  z-index: 1;
`;

export const Landing = () => {
    const [cards, setCards] = useState([]);

    useEffect(() => {
        const suits = ["clubs", "diamonds", "hearts", "spades"];
        const ranks = [
            "2", "3", "4", "5", "6", "7", "8", "9", "10",
            "jack", "queen", "king", "ace",
        ];
        const standardCards = ranks.flatMap((rank) =>
            suits.map((suit) => `${rank}_of_${suit}.png`)
        );

        const specialCards = [
            "black_joker.png",
            "red_joker.png",
            "ace_of_spades2.png",
        ];

        // Duplicate
        const duplicateCards = [
            "jack_of_clubs2.png", "jack_of_diamonds2.png", "jack_of_hearts2.png", "jack_of_spades2.png",
            "queen_of_clubs2.png", "queen_of_diamonds2.png", "queen_of_hearts2.png", "queen_of_spades2.png",
            "king_of_clubs2.png", "king_of_diamonds2.png", "king_of_hearts2.png", "king_of_spades2.png",
        ];

        // Excluded
        const excludedCards = ["face_down.jpg", "github-mark-white.png"];

        const allCards = [
            ...standardCards,
            ...specialCards,
            ...duplicateCards,
        ].filter((card) => !excludedCards.includes(card));

        // Generate card elements
        const cardElements = Array.from({ length: 30 }).map(() => {
            const randomCard = allCards[Math.floor(Math.random() * allCards.length)];
            return {
                src: `/images/${randomCard}`,
                top: Math.random() * 100,
                left: Math.random() * 100,
                rotation: Math.random() * 360 - 180,
                duration: Math.random() * 8 + 5,
            };
        });

        setCards(cardElements);
    }, []);




    return (
        <LandingBase>
            <JigglingText>Welcome to GrahamCard</JigglingText>
            {cards.map((card, index) => (
                <Card
                    key={index}
                    src={card.src}
                    top={card.top}
                    left={card.left}
                    rotation={card.rotation}
                    duration={card.duration}
                />
            ))}
        </LandingBase>
    );
};