/* Copyright G. Hemingway, 2024 - All rights reserved */
"use strict";

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const CardImg = styled.img`
  position: absolute;
  height: auto;
  width: 100%;
`;

export const Card = ({ card, top, left, onClick, isSelected }) => {
  const source = card.up
    ? `/images/${card.value}_of_${card.suit}.png`
    : "/images/face_down.jpg";
  const style = { left: `${left}%`, top: `${top}%`, border: isSelected ? '2px solid blue' : 'none', borderRadius: isSelected ? '5px' : '0' };
  const id = `${card.suit}:${card.value}`;
  return <CardImg id={id} onClick={onClick} style={style} src={source} />;
};

const PileBase = styled.div`
  margin: 5px;
  position: relative;
  display: inline-block;
  border: dashed 2px #ffffff;
  border-radius: 5px;
  width: 12%;
`;

const PileFrame = styled.div`
  margin-top: 140%;
`;

export const Pile = ({
  cards = [],
  spacing = 8,
  horizontal = false,
  up,
  onClick,
  selectedCards = []
}) => {
  const children = cards.map((card, i) => {
    const top = horizontal ? 0 : i * spacing;
    const left = horizontal ? i * spacing : 0;
    const isSelected = selectedCards.some(sc =>
        sc.suit === card.suit && sc.value === card.value
    );
    return (
      <Card
        key={i}
        card={card}
        up={up}
        top={top}
        left={left}
        onClick={onClick}
        isSelected={isSelected}
      />
    );
  });
  return (
    <PileBase>
      <PileFrame />
      {children}
    </PileBase>
  );
};

Card.propTypes = {
  card: PropTypes.object.isRequired,
  top: PropTypes.number,
  left: PropTypes.number,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool
};

Pile.propTypes = {
  cards: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClick: PropTypes.func,
  horizontal: PropTypes.bool,
  spacing: PropTypes.number,
  maxCards: PropTypes.number,
  top: PropTypes.number,
  left: PropTypes.number,
  selectedCards: PropTypes.array
};
