import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";

const PageWrapper = styled.div`
    display: flex;
    width: 100%;
    min-height: 100vh;
    justify-content: center;
    background-color: #f9f9f9;
`;

const FormContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    font-family: Arial, sans-serif;
    width: 100%;
    max-width: 800px;  /* Added max-width */
`;

const Title = styled.h2`
    font-size: 2rem;
    color: #333;
    margin-bottom: 1.5rem;
    text-align: center;
`;

const Form = styled.form`
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 2rem;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Label = styled.label`
    font-size: 1rem;
    color: #555;
    margin-bottom: 0.5rem;
    display: flex;
    flex-direction: column;
`;

const Input = styled.input`
    font-size: 1rem;
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: all 0.3s ease;

    &:focus {
        border-color: #007bff;
        box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
        outline: none;
    }
`;

const Button = styled.button`
    font-size: 1rem;
    padding: 0.75rem;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background-color: #0056b3;
    }

    &:disabled {
        background-color: #cccccc;
        cursor: not-allowed;
    }
`;

const Error = styled.p`
    color: red;
    margin-bottom: 1rem;
    text-align: center;
`;

export const EditProfile = () => {
    const { username } = useParams();
    const navigate = useNavigate();
    const [formState, setFormState] = useState({
        first_name: "",
        last_name: "",
        city: "",
    });
    const [error, setError] = useState("");

    useEffect(() => {
        fetch(`/v1/user/${username}`)
            .then((res) => {
                if (!res.ok) throw new Error("Failed to fetch user data");
                return res.json();
            })
            .then((data) => {
                setFormState({
                    first_name: data.first_name || "",
                    last_name: data.last_name || "",
                    city: data.city || "",
                });
            })
            .catch((err) => setError("Error fetching profile data."));
    }, [username]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormState({ ...formState, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setError("");

        fetch(`/v1/user`, {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(formState),
        })
            .then((res) => {
                if (res.ok) {
                    navigate(`/profile/${username}`);
                } else {
                    return res.json().then((data) => {
                        throw new Error(data.error || "Failed to update profile");
                    });
                }
            })
            .catch((err) => setError(err.message));
    };

    return (
        <div style={{gridArea: 'main'}}>
            <FormContainer>
                <Title>Edit Profile</Title>
                {error && <Error>{error}</Error>}
                <Form onSubmit={handleSubmit}>
                    <Label>
                        First Name:
                        <Input
                            type="text"
                            name="first_name"
                            value={formState.first_name}
                            onChange={handleInputChange}
                        />
                    </Label>
                    <Label>
                        Last Name:
                        <Input
                            type="text"
                            name="last_name"
                            value={formState.last_name}
                            onChange={handleInputChange}
                        />
                    </Label>
                    <Label>
                        City:
                        <Input
                            type="text"
                            name="city"
                            value={formState.city}
                            onChange={handleInputChange}
                        />
                    </Label>
                    <Button type="submit">Save Changes</Button>
                </Form>
            </FormContainer>
        </div>
    );
};