/* Copyright G. Hemingway, 2024 - All rights reserved */
"use strict";

import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { ErrorMessage, FormButton } from "./shared.js";

const gameNames = [
  "klondike",
  "pyramid",
  "canfield",
  "golf",
  "yukon",
  "hearts",
];

const GameTypesBase = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 1em;
`;

const GameTypes = ({ game, onChange }) => {
  const games = gameNames.map((g, i) => (
    <GameChoice key={i} game={g} selected={game === g} onChange={onChange} />
  ));
  return <GameTypesBase>{games}</GameTypesBase>;
};

const GameLabel = styled.label``;

const GameTypeInput = styled.input``;

const GameChoice = ({ game, selected, onChange }) => {
  return (
    <GameLabel>
      <GameTypeInput
        type="radio"
        name="game"
        value={game}
        checked={selected}
        onChange={onChange}
      />
      {game}
    </GameLabel>
  );
};

GameChoice.propTypes = {
  game: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const StartBase = styled.div`
  grid-area: main;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);
`;

const StartForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 50%;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background: #f6f8fa;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  position: relative;
  margin-top: -2rem;
`;

const Header = styled.h1`
  margin-bottom: 1.5rem;
  font-weight: bold;
  color: #333;
  margin-top: -2.5rem;
`;


const CenteredContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 60px);
  background-color: transparent;
`;

const StyledLabel = styled.label`
  font-size: 1rem;
  font-weight: 500;
  color: #333;
`;

const StyledSelect = styled.select`
  padding: 0.75rem;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 6px;
  width: 100%;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.15);
  }
`;

const RadioGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #333;
  gap: 0.5rem;

  input[type="radio"] {
    margin: 0;
  }
`;

const InputGroup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  width: 100%;
`;

const BaseButton = styled.button`
  width: 60%;
  display: flex;
  justify-content: center;
  background-color: dodgerblue;
  align-items: center;
  color: #fff;
  font-size: 2rem;
  border: none;
  border-radius: 5px;
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.2s ease;
`;

const StyledButton = styled(BaseButton)`
  background-color: dodgerblue;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;

  &:hover {
    background-color: #53aaff;
  }
`;

export const Start = ({ history }) => {
  let navigate = useNavigate();
  let [state, setState] = useState({
    game: "klondike",
    draw: "Draw 1",
    color: "Red",
  });
  let [error, setError] = useState("");

  const onSubmit = async (ev) => {
    ev.preventDefault();
    const response = await fetch("/v1/game", {
      body: JSON.stringify({
        game: state.game,
        draw: state.draw,
        color: state.color,
      }),
      method: "POST",
      credentials: "include",
      headers: {
        "content-type": "application/json",
      },
    });
    const data = await response.json();
    if (response.ok) {
      navigate(`/game/${data.id}`);
    } else {
      setError(`Error: ${data.error}`);
    }
  };

  const onChange = (ev) =>
      setState({
        ...state,
        [ev.target.name]: ev.target.value,
      });

  return (
      <StartBase>
        <FormContainer>
          <ErrorMessage msg={error} />
          <Header>Create New Game</Header>
          <StartForm onSubmit={onSubmit}>
            <Column>
              <StyledLabel>Game Type</StyledLabel>
              <RadioGroup>
                {gameNames.map((g, i) => (
                    <RadioLabel key={i}>
                      <input
                          type="radio"
                          name="game"
                          value={g}
                          checked={state.game === g}
                          onChange={onChange}
                      />
                      {g}
                    </RadioLabel>
                ))}
              </RadioGroup>
            </Column>
            <Column>
              <InputGroup>
                <StyledLabel htmlFor="draw">Draw</StyledLabel>
                <StyledSelect
                    id="draw"
                    name="draw"
                    disabled={"hearts" === state.game}
                    onChange={onChange}
                    value={state.draw}
                >
                  <option>Draw 1</option>
                  <option>Draw 3</option>
                </StyledSelect>
              </InputGroup>
              <InputGroup>
                <StyledLabel htmlFor="color">Card Color</StyledLabel>
                <StyledSelect
                    id="color"
                    name="color"
                    onChange={onChange}
                    value={state.color}
                >
                  <option>Red</option>
                  <option>Green</option>
                  <option>Blue</option>
                  <option>Magical</option>
                </StyledSelect>
              </InputGroup>
            </Column>
          </StartForm>
          <StyledButton type="submit" onClick={onSubmit}>
            Start
          </StyledButton>
        </FormContainer>
      </StartBase>
  );
};

Start.propTypes = {};
